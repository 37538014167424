<script setup>
import { computed } from "vue";

const $device = useDevice();

const backgroundImage = computed(() =>
  $device.isDesktop
    ? "url(/img/unleash-desktop.webp)"
    : "url(/img/unleash-mobile.webp)"
);
</script>

<template>
    <section class="unleash py-20 px-5 common bg-transparent" :style="`background-image: ${backgroundImage}`">
        <div class="py-10 text-center w-full">
            <h2 class="text-white">
                <span class="max-w-2xl inline-block">
                    <SplitAnimate
                        text="Ready to unleash your business's"
                        text2="full potential?"
                        text2Class="text-jade lg:text-white"
                    />
                </span>
            </h2>
            <div class="text-center">
                <span class="description mt-4 text-white60 inline-block max-w-2xl">
                    Don't wait to achieve your business goals. Schedule a
                    consultation and let us help you make them a reality.
                </span>
            </div>
            <Button target="_blank" url="https://calendly.com/nir-60/1h-intro" :arrow="true" buttonClass="mt-10 block">
                Book your strategy call
            </Button>
        </div>
    </section>
</template>
